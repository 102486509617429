
import { apiFunction } from "../../apiCalls/function";
import * as APIs from "../../apiCalls/urls/admin/auth";

export const handleLogin = (data) => {
    return apiFunction(APIs.LOGIN, "post", data, null, "admin");
};

// export const getTokenData = (data) => {
//     return apiFunction(GET_TOKEN, "post", data, true, null);
// };

// export const getPermission = () => {
//     return apiFunction(GET_PERMISSION, "post", {}, true, null);
// };
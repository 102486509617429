import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
} from "../actions";
import Logo from "../assets/images/logo.svg";
import LogoWhite from "../assets/images/bihar-logo-red.png";
import UserImage from "../assets/images/user.png";
import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import Sidebar from "./navbar/sidebar";

class NavbarMenu extends React.Component {
  state = {
    linkupdate: false,
  };

  componentDidMount() {
    // Access localStorage on component load
    const data = JSON.parse(localStorage.getItem("call-center-admin-token"));
    if (data) {
      // Do something with the data
      if (
        data.vchDesignationName === "Block Education Officer" ||
        data.vchDesignationName === "District Education Officer" ||
        data.vchDesignationName === "State Project Director" ||
        data.vchDesignationName === "Additional State Project Director, Bihar" ||
        data.vchDesignationName === "Additional State Project Director- Programme" ||
        data.vchDesignationName === "Director, Secondary" ||
        data.vchDesignationName === "Director, Primary" ||
        data.vchDesignationName === "Director, MDM" ||
        data.vchDesignationName === "Director, Administration" ||
        data.vchDesignationName === "Director, SCERT" ||
        data.vchDesignationName === "Dy. Director, Education" ||
        data.vchDesignationName === "Assistant Director, Education" ||
        data.vchDesignationName === "Additional State Project Director - Academic" ||
        data.vchDesignationName === "DRT"
      ) {
        document.getElementById("mob").style.display = "none";
      }
    }
  }

  render() {
    // console.log(this.props.auth);
    const { themeColor, toggleNotification, toggleEqualizer } = this.props;
   

    document.body.classList.add(themeColor);

    return (
      <div id="mob">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-btn">
              <button
                className="btn-toggle-offcanvas"
                onClick={() => {
                  this.props.onPressSideMenuToggle();
                }}
              >
                <i className="lnr lnr-menu fa fa-bars"></i>
              </button>
            </div>

            <div className="navbar-brand">
              <a href="dashboard" style={{ fontWeight: 900, color: "#32867b" }}>
                CALL CONNECT
              </a>
            </div>

            <div className="navbar-right">
              {/* <form id="navbar-search" className="navbar-form search-form">
                                <input
                                    className="form-control"
                                    placeholder="Search here..."
                                    type="text"
                                />
                                <button type="button" className="btn btn-default">
                                    <i className="icon-magnifier"></i>
                                </button>
                            </form> */}

              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  {/* <li>
                                        <a
                                            href="filedocuments"
                                            className="icon-menu d-none d-sm-block d-md-none d-lg-block"
                                        >
                                            <i className="fa fa-folder-open-o"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="appcalendar"
                                            className="icon-menu d-none d-sm-block d-md-none d-lg-block"
                                        >
                                            <i className="icon-calendar"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="appchat" className="icon-menu d-none d-sm-block">
                                            <i className="icon-bubbles"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="appinbox" className="icon-menu d-none d-sm-block">
                                            <i className="icon-envelope"></i>
                                            <span className="notification-dot"></span>
                                        </a>
                                    </li> */}
                                    {/* <li
                                        className={
                                            toggleNotification ? "show dropdown" : "dropdown"
                                        }
                                    >
                                        <a
                                            href="#!"
                                            className="dropdown-toggle icon-menu"
                                            data-toggle="dropdown"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.onPressNotification();
                                            }}
                                        >
                                            <i className="icon-bell"></i>
                                            <span className="notification-dot"></span>
                                        </a>
                                        <ul
                                            className={
                                                toggleNotification
                                                    ? "dropdown-menu notifications show"
                                                    : "dropdown-menu notifications"
                                            }
                                        >
                                            <li className="header">
                                                <strong>You have 4 new Notifications</strong>
                                            </li>
                                            <li>
                                                <a>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <i className="icon-info text-warning"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="text">
                                                                Campaign <strong>Holiday Sale</strong> is nearly
                                                                reach budget limit.
                                                            </p>
                                                            <span className="timestamp">10:00 AM Today</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <i className="icon-like text-success"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="text">
                                                                Your New Campaign <strong>Holiday Sale</strong>{" "}
                                                                is approved.
                                                            </p>
                                                            <span className="timestamp">11:30 AM Today</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <i className="icon-pie-chart text-info"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="text">
                                                                Website visits from Twitter is 27% higher than
                                                                last week.
                                                            </p>
                                                            <span className="timestamp">04:00 PM Today</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <i className="icon-info text-danger"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="text">
                                                                Error on website analytics configurations
                                                            </p>
                                                            <span className="timestamp">Yesterday</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="footer">
                                                <a className="more">See all notifications</a>
                                            </li>
                                        </ul>
                                    </li> */}
                  {/* <li
                                        className={toggleEqualizer ? "show dropdown" : "dropdown"}
                                    >
                                        <a
                                            href="#!"
                                            className="dropdown-toggle icon-menu"
                                            data-toggle="dropdown"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.onPressEqualizer();
                                            }}
                                        >
                                            <i className="icon-equalizer"></i>
                                        </a>
                                        <ul
                                            className={
                                                toggleEqualizer
                                                    ? "dropdown-menu user-menu menu-icon show"
                                                    : "dropdown-menu user-menu menu-icon"
                                            }
                                        >
                                            <li className="menu-heading">ACCOUNT SETTINGS</li>
                                            <li>
                                                <a>
                                                    <i className="icon-note"></i> <span>Basic</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="icon-equalizer"></i>{" "}
                                                    <span>Preferences</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="icon-lock"></i> <span>Privacy</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="icon-bell"></i>{" "}
                                                    <span>Notifications</span>
                                                </a>
                                            </li>
                                            <li className="menu-heading">BILLING</li>
                                            <li>
                                                <a>
                                                    <i className="icon-credit-card"></i>{" "}
                                                    <span>Payments</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="icon-printer"></i> <span>Invoices</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="icon-refresh"></i> <span>Renewals</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li> */}
                  <li>
                    <a href="/logout" className="icon-menu">
                      <i className="icon-login"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
          <div className="sidebar-scroll">
            <div className="user-account">
              <img
                src={UserImage}
                className="rounded-circle user-photo"
                alt="User Profile Picture"
              />
              <Dropdown>
                <span>Welcome,</span>
                <Dropdown.Toggle
                  variant="none"
                  as="a"
                  id="dropdown-basic"
                  className="user-name"
                >
                  <strong>{this.props.auth?.auth?.userName}</strong>
                </Dropdown.Toggle>

                {/* <Dropdown.Menu className="dropdown-menu-right account">
                                    <Dropdown.Item href="profilev2page">
                                        <i className="icon-user"></i>My Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href="appinbox">
                                        {" "}
                                        <i className="icon-envelope-open"></i>Messages
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        {" "}
                                        <i className="icon-settings"></i>Settings
                                    </Dropdown.Item>
                                    <li className="divider"></li>
                                    <Dropdown.Item href="/logout">
                                        {" "}
                                        <i className="icon-power"></i>Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu> */}
              </Dropdown>
              <hr />
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChild: PropTypes.array.isRequired,
  addClassactiveChildApp: PropTypes.array.isRequired,
  addClassactiveChildFM: PropTypes.array.isRequired,
  addClassactiveChildBlog: PropTypes.array.isRequired,
  addClassactiveChildUI: PropTypes.array.isRequired,
  addClassactiveChildWidgets: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildPages: PropTypes.array.isRequired,
  addClassactiveChildForms: PropTypes.array.isRequired,
  addClassactiveChildTables: PropTypes.array.isRequired,
  addClassactiveChildChart: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  generalSetting: PropTypes.array.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer, Auth }) => {
  const {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;
  return {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
    auth: Auth,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
})(NavbarMenu);

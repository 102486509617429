import * as actionTypes from "./actionTypes";
import { actionNotifier } from "../../components/ui/toast";
import { handleLogin, getTokenData, getPermission } from "./apis";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
// import { getAllPermission } from "../../Shared/permission";
import { notiflixLoaderStart, notiflixStatus, notiflixLoaderEnd } from '../../components/ui/notification'

// import { getDataFromStore } from "../../store/getStore";

export const onLogin = (state) => {
    return async (dispatch) => {
        // dispatch(apiLoading())
        notiflixLoaderStart()
        let result = await handleLogin(state)
        console.log(result);
        if (result.status) {
            localStorage.setItem("call-center-admin-token", JSON.stringify(result.data))
            dispatch(updateAuthData(result.data));
            // window.open("/", "_self")
        } else {
            actionNotifier.error(result.message);
        }
        notiflixLoaderEnd()
        // dispatch(apiLoadingEnd());
    };
};

export const onReload = () => {
    return async (dispatch) => {
        // let result = await getPermission()
        // // console.log(result);
        // if (result.status) {
        //     let token = localStorage.getItem("call-center-admin-token")
        //     if (token) {
        //         token = JSON.parse(token)
        //         token = {
        //             ...result.data,
        //             token: token.token
        //         }
        //         // console.log(token);
        //         dispatch(updateAuthData(token));
        //         dispatch(updateReducer("allPermissionJson", getAllPermission(result.data.globalPermissions)))
        //     }
        // }
        let token = localStorage.getItem("call-center-admin-token")
        if (token) {
            token = JSON.parse(token)
            dispatch(updateAuthData(token));
        }
    };
};


export const onLogout = () => {
    return (dispatch) => {
        localStorage.removeItem("call-center-admin-token")
        window.open("/", "_self")
    }
};


export const updateAuthData = (data) => {
    return {
        type: actionTypes.UPDATE_AUTH_DATA,
        payload: data,
    };
};


export const resetAuth = (data) => {
    return {
        type: actionTypes.RESET,
    };
};

export const updateReducer = (key, data) => {
    return {
        type: actionTypes.UPDATE_REDUCER,
        payload: { key, data }
    };
};





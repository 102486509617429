import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Routes from "./routes"
import Loader from "./components/ui/loader"
import Toast from "./components/ui/toast";
import { onReload } from './store/auth/actions'
import "react-toastify/dist/ReactToastify.css";

window.__DEV__ = true;

const App = (props) => {

    useEffect(() => {
        props.onReload()
    }, [])
    return (
        <div id="wrapper">
            <Routes
                token={props.auth?.auth?.token}
            />
            {props.notification.loading ? <Loader active={props.notification.loading} /> : null}
            <Toast />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        notification: state.Notification,
        auth: state.Auth,
    }
}

// const mapDispatchToProps = { updateAdminFromLocal, getAvailableToken, onReload }

export default withRouter(connect(mapStateToProps, { onReload })(App));

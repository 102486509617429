import { combineReducers } from "redux";

import Auth from "./auth/reducer";
import Notification from "./notification/reducer";
import loginReducer from "../reducers/loginReducer";
import navigationReducer from "../reducers/navigationReducer";
import analyticalReducer from "../reducers/analyticalReducer";
import demographicReducer from "../reducers/demographicReducer";
import ioTReducer from "../reducers/ioTReducer";
import mailInboxReducer from "../reducers/mailInboxReducer";
import UIElementsReducer from "../reducers/UIElementsReducer";


const rootReducer = combineReducers({
  Auth,
  Notification,
  loginReducer,
  navigationReducer: navigationReducer,
  analyticalReducer: analyticalReducer,
  demographicReducer: demographicReducer,
  ioTReducer: ioTReducer,
  mailInboxReducer: mailInboxReducer,
  UIElementsReducer: UIElementsReducer,
});

export default rootReducer;

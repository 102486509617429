import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
} from "../../actions";
import Avatar4 from "../../assets/images/xs/avatar4.jpg";
import Avatar5 from "../../assets/images/xs/avatar5.jpg";
import Avatar2 from "../../assets/images/xs/avatar2.jpg";
import Avatar1 from "../../assets/images/xs/avatar1.jpg";
import Avatar3 from "../../assets/images/xs/avatar3.jpg";

class NavbarMenu extends React.Component {
  state = {
    linkupdate: false,
    userType: "",
    officer: "",
  };
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("call-center-admin-token"));
    this.setState({
      userType: user?.userType,
      officer: user?.vchDesignationName,
    });

    this.props.tostMessageLoad(true);
    var res = window.location.pathname;
    res = res.split("/");
    res = res.length > 4 ? res[4] : "/";
    const { activeKey } = this.props;
    this.activeMenutabwhenNavigate("/" + activeKey);
  }

  activeMenutabwhenNavigate(activeKey) {
    if (
      activeKey === "/dashboard" ||
      activeKey === "/demographic" ||
      activeKey === "/ioT"
    ) {
      this.activeMenutabContainer("dashboradContainer");
    } else if (
      activeKey === "/appinbox" ||
      activeKey === "/appchat" ||
      activeKey === "/appcalendar" ||
      activeKey === "/appcontact" ||
      activeKey === "/apptaskbar"
    ) {
      this.activeMenutabContainer("AppContainer");
    } else if (
      activeKey === "/filemanagerdashboard" ||
      activeKey === "/filedocuments" ||
      activeKey === "/filemedia"
    ) {
      this.activeMenutabContainer("FileManagerContainer");
    } else if (
      activeKey === "/blognewpost" ||
      activeKey === "/bloglist" ||
      activeKey === "/blogdetails"
    ) {
      this.activeMenutabContainer("BlogContainer");
    } else if (
      activeKey === "/uitypography" ||
      activeKey === "/uitabs" ||
      activeKey === "/uibuttons" ||
      activeKey === "/bootstrapui" ||
      activeKey === "/uiicons" ||
      activeKey === "/uinotifications" ||
      activeKey === "/uicolors" ||
      activeKey === "/uilistgroup" ||
      activeKey === "/uimediaobject" ||
      activeKey === "/uimodal" ||
      activeKey === "/uiprogressbar"
    ) {
      this.activeMenutabContainer("UIElementsContainer");
    } else if (
      activeKey === "/widgetsdata" ||
      activeKey === "/widgetsweather" ||
      activeKey === "/widgetsblog" ||
      activeKey === "/widgetsecommers"
    ) {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (activeKey === "/login") {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (
      activeKey === "/teamsboard" ||
      activeKey === "/profilev2page" ||
      activeKey === "/helperclass" ||
      activeKey === "/searchresult" ||
      activeKey === "/invoicesv2" ||
      activeKey === "/invoices" ||
      activeKey === "/pricing" ||
      activeKey === "/timeline" ||
      activeKey === "/profilev1page" ||
      activeKey === "/blankpage" ||
      activeKey === "/imagegalleryprofile" ||
      activeKey === "/projectslist" ||
      activeKey === "/maintanance" ||
      activeKey === "/testimonials" ||
      activeKey === "/faqs"
    ) {
      this.activeMenutabContainer("PagesContainer");
    } else if (
      activeKey === "/formvalidation" ||
      activeKey === "/basicelements"
    ) {
      this.activeMenutabContainer("FormsContainer");
    } else if (activeKey === "/tablenormal") {
      this.activeMenutabContainer("TablesContainer");
    } else if (activeKey === "/echart") {
      this.activeMenutabContainer("chartsContainer");
    } else if (activeKey === "/leafletmap") {
      this.activeMenutabContainer("MapsContainer");
    }
  }

  // componentWillReceiveProps(){
  //   this.setState({
  //     linkupdate:!this.state.linkupdate
  //   })
  // }

  activeMenutabContainer(id) {
    var parents = document.getElementById("main-menu");
    var activeMenu = document.getElementById(id);

    for (let index = 0; index < parents.children.length; index++) {
      if (parents.children[index].id !== id) {
        parents.children[index].classList.remove("active");
        parents.children[index].children[1].classList.remove("in");
      }
    }
    setTimeout(() => {
      activeMenu.classList.toggle("active");
      activeMenu.children[1].classList.toggle("in");
    }, 10);
  }
  render() {
    const {
      addClassactive,
      addClassactiveChildAuth,
      addClassactiveChildMaps,
      themeColor,
      sideMenuTab,
      activeKey,
    } = this.props;
    var path = window.location.pathname;
    document.body.classList.add(themeColor);
    const { userType, officer } = this.state;

    return (
      <div>
        <div className="tab-content p-l-0 p-r-0">
          <div
            className={sideMenuTab[0] ? "tab-pane active show" : "tab-pane"}
            id="menu"
          >
            <Nav id="left-sidebar-nav" className="sidebar-nav">
              <ul id="main-menu" className="metismenu">
                <ul className="">
                  <li className={activeKey === "add-complain" ? "active" : ""}>
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className={activeKey === "add-complain" ? "active" : ""}>
                    <Link to="/add-complain">Add Complaint</Link>
                  </li>
                  <li className={activeKey === "view-complain" ? "active" : ""}>
                    <Link to="/view-complain">View Complaint</Link>
                  </li>
                  {/* <li className={activeKey === "view-complain" ? "active" : ""}>
                    <Link to="/reports">Reports</Link>
                  </li> */}

                  {userType === "ADMIN" ? (
                    <li
                      className={activeKey === "create-account" ? "active" : ""}
                    >
                      <Link to="/create-account">Create Account</Link>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className={activeKey === "view-complain" ? "active" : ""}>
                    <Link to="/pendency?type=STATE&code=0&category=all">
                      Daily Summary Report
                    </Link>
                  </li>

                  {/* <li
                      className={activeKey === "create-account" ? "active" : ""}
                    >
                      <Link to="/create-account">Create Account</Link>
                    </li> */}
                </ul>
              </ul>
            </Nav>
          </div>
          <div
            className={
              sideMenuTab[1]
                ? "tab-pane p-l-15 p-r-15 show active"
                : "tab-pane p-l-15 p-r-15"
            }
            id="Chat"
          >
            <form>
              <div className="input-group m-b-20">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="icon-magnifier"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
              </div>
            </form>
            <ul className="right_chat list-unstyled">
              <li className="online">
                <a>
                  <div className="media">
                    <img className="media-object " src={Avatar4} alt="" />
                    <div className="media-body">
                      <span className="name">Chris Fox</span>
                      <span className="message">Designer, Blogger</span>
                      <span className="badge badge-outline status"></span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="online">
                <a>
                  <div className="media">
                    <img className="media-object " src={Avatar5} alt="" />
                    <div className="media-body">
                      <span className="name">Joge Lucky</span>
                      <span className="message">Java Developer</span>
                      <span className="badge badge-outline status"></span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="offline">
                <a>
                  <div className="media">
                    <img className="media-object " src={Avatar2} alt="" />
                    <div className="media-body">
                      <span className="name">Isabella</span>
                      <span className="message">CEO, Thememakker</span>
                      <span className="badge badge-outline status"></span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="offline">
                <a>
                  <div className="media">
                    <img className="media-object " src={Avatar1} alt="" />
                    <div className="media-body">
                      <span className="name">Folisise Chosielie</span>
                      <span className="message">Art director, Movie Cut</span>
                      <span className="badge badge-outline status"></span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="online">
                <a>
                  <div className="media">
                    <img className="media-object " src={Avatar3} alt="" />
                    <div className="media-body">
                      <span className="name">Alexander</span>
                      <span className="message">Writter, Mag Editor</span>
                      <span className="badge badge-outline status"></span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div
            className={
              sideMenuTab[2]
                ? "tab-pane p-l-15 p-r-15 show active"
                : "tab-pane p-l-15 p-r-15"
            }
            id="setting"
          >
            <h6>Choose Mode</h6>
            <ul className="choose-skin list-unstyled">
              <li
                data-theme="white"
                className={
                  document.body.classList.contains("full-dark") ? "" : "active"
                }
                onClick={() => {
                  this.setState({ somethi: false });
                  document.body.classList.remove("full-dark");
                }}
              >
                <div className="white"></div>
                <span>Light</span>
              </li>
              <li
                data-theme="black"
                className={
                  document.body.classList.contains("full-dark") ? "active" : ""
                }
                onClick={() => {
                  this.setState({ somethi: true });
                  document.body.classList.add("full-dark");
                }}
              >
                <div className="black"></div>
                <span>Dark</span>
              </li>
            </ul>
            <hr />
            <h6>Choose Skin</h6>
            <ul className="choose-skin list-unstyled">
              <li
                data-theme="purple"
                className={themeColor === "theme-purple" ? "active" : ""}
              >
                <div
                  className="purple"
                  onClick={() => {
                    if (themeColor !== "theme-purple") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("purple");
                  }}
                ></div>
                <span>Purple</span>
              </li>
              <li
                data-theme="blue"
                className={themeColor === "theme-blue" ? "active" : ""}
              >
                <div
                  className="blue"
                  onClick={() => {
                    if (themeColor !== "theme-blue") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("blue");
                  }}
                ></div>
                <span>Blue</span>
              </li>
              <li
                data-theme="cyan"
                className={themeColor === "theme-cyan" ? "active" : ""}
              >
                <div
                  className="cyan"
                  onClick={() => {
                    if (themeColor !== "theme-cyan") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("cyan");
                  }}
                ></div>
                <span>Cyan</span>
              </li>
              <li
                data-theme="green"
                className={themeColor === "theme-green" ? "active" : ""}
              >
                <div
                  className="green"
                  onClick={() => {
                    if (themeColor !== "theme-green") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("green");
                  }}
                ></div>
                <span>Green</span>
              </li>
              <li
                data-theme="orange"
                className={themeColor === "theme-orange" ? "active" : ""}
              >
                <div
                  className="orange"
                  onClick={() => {
                    if (themeColor !== "theme-orange") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("orange");
                  }}
                ></div>
                <span>Orange</span>
              </li>
              <li
                data-theme="blush"
                className={themeColor === "theme-blush" ? "active" : ""}
              >
                <div
                  className="blush"
                  onClick={() => {
                    if (themeColor !== "theme-blush") {
                      document.body.classList.remove(themeColor);
                    }
                    this.props.onPressThemeColor("blush");
                  }}
                ></div>
                <span>Blush</span>
              </li>
            </ul>
            <hr />
            <h6>General Settings</h6>
            <ul className="setting-list list-unstyled">
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Report Panel Usag</span>
                </label>
              </li>
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Email Redirect</span>
                </label>
              </li>
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Notifications</span>
                </label>
              </li>
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Auto Updates</span>
                </label>
              </li>
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Offline</span>
                </label>
              </li>
              <li>
                <label className="fancy-checkbox">
                  <input type="checkbox" name="checkbox" />
                  <span>Location Permission</span>
                </label>
              </li>
            </ul>
          </div>
          <div
            className={
              sideMenuTab[3]
                ? "tab-pane p-l-15 p-r-15 show active"
                : "tab-pane p-l-15 p-r-15"
            }
            id="question"
          >
            <form>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="icon-magnifier"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
              </div>
            </form>
            <ul className="list-unstyled question">
              <li className="menu-heading">HOW-TO</li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  How to Create Campaign
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Boost Your Sales
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Website Analytics
                </a>
              </li>
              <li className="menu-heading">ACCOUNT</li>
              <li>
                <a
                  href="registration"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Cearet New Account
                </a>
              </li>
              <li>
                <a
                  href="forgotpassword"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Change Password?
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Privacy &amp; Policy
                </a>
              </li>
              <li className="menu-heading">BILLING</li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Payment info
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Auto-Renewal
                </a>
              </li>
              <li className="menu-button m-t-30">
                <a
                  href="#!"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <i className="icon-question"></i> Need Help?
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChild: PropTypes.array.isRequired,
  addClassactiveChildApp: PropTypes.array.isRequired,
  addClassactiveChildFM: PropTypes.array.isRequired,
  addClassactiveChildBlog: PropTypes.array.isRequired,
  addClassactiveChildUI: PropTypes.array.isRequired,
  addClassactiveChildWidgets: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildPages: PropTypes.array.isRequired,
  addClassactiveChildForms: PropTypes.array.isRequired,
  addClassactiveChildTables: PropTypes.array.isRequired,
  addClassactiveChildChart: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  generalSetting: PropTypes.array.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer }) => {
  const {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;
  return {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
})(NavbarMenu);

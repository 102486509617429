import React from "react"
import "./styles.css";

const Loader = () => {
    return (
        <div className="loader"></div>
    )

}

export default Loader
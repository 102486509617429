import React, { lazy, Suspense } from 'react'
import { Route, Switch } from "react-router-dom";

import NavbarMenu from "../components/NavbarMenu";

const Login = lazy(() => import("../screens/Login"));
const Logout = lazy(() => import("../screens/Auth/Logout"));
// import registration from "../screens/Auth/Registration";
// import lockscreen from "../screens/Auth/Lockscreen";
// import forgotpassword from "../screens/Auth/ForgotPassword";
// import page404 from "../screens/Auth/Page404";
// import page403 from "../screens/Auth/Page403";
// import page500 from "../screens/Auth/Page500";
// import page503 from "../screens/Auth/Page503";

// import MyTable from "../screens/Dashbord/MyTable";
// import MyForm from "../screens/Dashbord/MyForm"
const AddComplain = lazy(() => import("../screens/AddComplain"));
const ViewComplain = lazy(() => import("../screens/ViewComplain"));
const ActionLog = lazy(() => import('../screens/ActionLog'));
const CreateAccount = lazy(() => import('../screens/CreateAccount'));
const Dashboard = lazy(() => import('../screens/Dashbord'));

//BEo _DEO
const BEODash = lazy(() => import('../screens/officer/dashboard'));
const BEOComplain = lazy(() => import('../screens/officer/complain'));
const BEOComplainInfo = lazy(() => import('../screens/officer/complainInfo'));
const BEOActionLog = lazy(() => import('../screens/officer/actionLog'));
const BEOAuth = lazy(() => import('../screens/officer/auth'));
const Reports = lazy(() => import('../screens/Report'));
const PendencyReport = lazy(() => import('../screens/pendencyReport'));
const PendencyReportCount = lazy(() => import('../screens/pendencyReport/singleView'));
const PendencyReportOfficer = lazy(() => import('../screens/officer/pendencyReportOfficer'));
const PendencyReportOfficerCount = lazy(() => import('../screens/officer/pendencyReportOfficer/singleView'));

const RouteData = (props) => {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;
    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                {!props.token ? (
                    <Switch>
                        {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
                        <Route
                            exact
                            path={`/`}
                            component={Login}
                        />
                        <Route
                            exact
                            path={`/login`}
                            component={Login}
                        />
                        <Route
                            exact
                            path={`/sso/auth/:token`}
                            component={BEOAuth}
                        />
                        <Route
                            exact
                            path={`/pendency`}
                            component={PendencyReport}
                        />
                        {/* <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/forgotpassword`}
                        component={forgotpassword}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/page404`}
                        component={page404}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/page403`}
                        component={page403}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/page500`}
                        component={page500}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/page503`}
                        component={page503}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/registration`}
                        component={registration}
                    />
                    <Route exact path={`registration`} component={registration} />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/lockscreen`}
                        component={lockscreen}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/maintanance`}
                        component={maintanance}
                    /> */}
                    </Switch>
                ) : (
                    <>
                        <NavbarMenu history={props.history} activeKey={activeKey1} />
                        <div id="main-content">
                            <Switch>
                                <Route
                                    exact
                                    path={`/`}
                                    component={Dashboard}
                                />
                                <Route
                                    exact
                                    path={`/add-complain`}
                                    component={AddComplain}
                                />
                                <Route
                                    exact
                                    path={`/view-complain`}
                                    component={ViewComplain}
                                />
                                <Route
                                    exact
                                    path={`/create-account`}
                                    component={CreateAccount}
                                />
                                <Route
                                    exact
                                    path={`/logout`}
                                    component={Logout}
                                />
                                <Route
                                    exact
                                    path={`/action-log/:id`}
                                    component={ActionLog}
                                />
                                <Route
                                    exact
                                    path={`/reports`}
                                    component={Reports}
                                />
                                <Route
                                    exact
                                    path={`/officer/dash`}
                                    component={BEODash}
                                />
                                <Route
                                    exact
                                    path={`/officer/complain/:status`}
                                    component={BEOComplain}
                                />
                                <Route
                                    exact
                                    path={`/officer/complainInfo/:id/:status`}
                                    component={BEOComplainInfo}
                                />
                                <Route
                                    exact
                                    path={`/officer/actionLog/:id/:status`}
                                    component={BEOActionLog}
                                />
                                <Route
                                    exact
                                    path={`/sso/auth/:token`}
                                    component={BEOAuth}
                                />
                                <Route
                                    exact
                                    path={`/pendency`}
                                    component={PendencyReport}
                                />
                                <Route
                                    exact
                                    path={`/pendency-officer`}
                                    component={PendencyReportOfficer}
                                />
                                <Route
                                    exact
                                    path={`/pendency-officer-view`}
                                    component={PendencyReportOfficerCount}
                                />
                                <Route
                                    exact
                                    path={`/pendency-view`}
                                    component={PendencyReportCount}
                                />
                                {/* <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/dashboard`}
                                component={dashboard}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/demographic`}
                                component={demographic}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/ioT`}
                                component={ioT}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/myTable`}
                                component={MyTable}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/myForm`}
                                component={MyForm}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/appinbox`}
                                component={appInbox}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/appchat`}
                                component={appChat}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/appcontact`}
                                component={appContact}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/apptaskbar`}
                                component={appTaskbar}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/filemanagerdashboard`}
                                component={filemanagerdashboard}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/filedocuments`}
                                component={filedocuments}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/filemedia`}
                                component={filemedia}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/fileimages`}
                                component={fileimages}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/blognewpost`}
                                component={blognewPost}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/blogdetails`}
                                component={blogdetails}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/bloglist`}
                                component={bloglist}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uitypography`}
                                component={uitypography}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uitabs`}
                                component={uitabs}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/bootstrapui`}
                                component={bootstrapui}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uiicons`}
                                component={uiicons}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uinotifications`}
                                component={uinotifications}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uicolors`}
                                component={uicolors}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uilistgroup`}
                                component={uilistgroup}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uimediaobject`}
                                component={uimediaobject}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uimodal`}
                                component={uimodal}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uibuttons`}
                                component={uibuttons}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/uiprogressbar`}
                                component={uiprogressbar}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/widgetsdata`}
                                component={widgetsdata}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/widgetsweather`}
                                component={widgetsweather}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/widgetsblog`}
                                component={widgetsblog}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/widgetsecommers`}
                                component={widgetsecommers}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/blankpage`}
                                component={blankpage}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/profilev1page`}
                                component={profilev1page}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/profilev2page`}
                                component={profilev2page}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/imagegalleryprofile`}
                                component={imagegalleryprofile}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/timeline`}
                                component={timeline}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pricing`}
                                component={pricing}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/invoices`}
                                component={invoices}
                            /> */}
                                {/* <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/invoicesv2`}
                                component={invoicesv2}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/searchresult`}
                                component={searchresult}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/helperclass`}
                                component={helperclass}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/teamsboard`}
                                component={teamsboard}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/projectslist`}
                                component={projectslist}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/testimonials`}
                                component={testimonials}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/faqs`}
                                component={faqs}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/formvalidation`}
                                component={formvalidation}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/basicelements`}
                                component={basicelements}
                            />
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/tablenormal`}
                                component={tablenormal}
                            /> */}
                            </Switch>
                        </div>
                    </>
                )}
            </Suspense>
        </div>
    )
}

export default RouteData;